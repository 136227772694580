<template>
  <Chart :options="chartOptions" class="h-full w-full" />
</template>

<script setup>
import { computed } from 'vue';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { baseHighchartsTheme } from './utils/ChartBaseTheming';

const props = defineProps({
  options: {
    type: Object,
    default: null
  },
  series: {
    type: Array,
    required: true
  }
});

const chartOptions = computed(() => {
  const monthly = props.options.xAxis?.monthly;
  const frequency = props.options?.tooltip?.selectedTimeframe?.frequency;

  return {
    ...props.options,
    series: props.series,
    chart: {
      ...props.options.chart,
      type: 'line'
    },
    plotOptions: {
      ...props.options.plotOptions,
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0.1).get('rgba')],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
            [2, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          ]
        }
      }
    },
    legend: {
      enabled: props.series.length > 1,
      ...props.options.legend
    },
    tooltip: {
      xDateFormat: monthly ? '%b %Y' : frequency == '1day' ? '%b %d, %Y' : '%l:%M %P, %b %d, %Y',
      ...props.options.tooltip
    }
  };
});

Highcharts.setOptions(baseHighchartsTheme());
</script>
